var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-address",class:{'search-address-full': _vm.hideStreetNumber}},[_c('ValidationProvider',{ref:"suggestionAddress",staticClass:"addresses",attrs:{"rules":"required","name":"suggestionAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectSuggestions',{attrs:{"search-input":_vm.search,"items":_vm.suggestionList,"no-filter":true,"placeholder":_vm.placeholder,"label":_vm.label,"error-messages":errors[0],"clearable":!_vm.isHouseNr,"disabled":_vm.isDisabled,"item-text":"title","item-value":_vm.itemText,"background-color":"white","data-test-id":"current-address-input"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"search":_vm.onSearch,"input":_vm.suggestionCheck,"keyup":_vm.resetInputs,"click:clear":_vm.resetInputs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.highlightedTitle)?_c('span',{domProps:{"innerHTML":_vm._s(item.highlightedTitle)}}):_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"appendItem",fn:function(){return [_c('div',{staticClass:"addresses-noData manual d-flex justify-space-between align-center py-3",on:{"click":_vm.toManual}},[_c('span',[_vm._v(_vm._s(_vm.$t('addressComponent.placeholders.manualAdd')))]),_c('v-icon',{staticClass:"reverse",attrs:{"size":"17","color":"primary"}},[_vm._v("$backArrow")])],1)]},proxy:true}],null,true),model:{value:(_vm.address.suggestionAddress),callback:function ($$v) {_vm.$set(_vm.address, "suggestionAddress", $$v)},expression:"address.suggestionAddress"}})]}}])}),(!_vm.hideStreetNumber)?_c('ValidationProvider',{ref:"searchStreetNumber",staticClass:"search-address-number",attrs:{"rules":"required","name":"searchStreetNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{ref:"searchStreetNumber",attrs:{"label":_vm.$t('addressComponent.placeholders.streetNumber'),"disabled":_vm.isHouseNr || _vm.isDisabled,"error-messages":errors[0],"loading":_vm.isSuggestCheckLoad,"readonly":_vm.isSuggestCheckLoad,"background-color":"white","data-test-id":"current-address-house-input"},on:{"keyup":_vm.onSearchHouse},model:{value:(_vm.streetNumber),callback:function ($$v) {_vm.streetNumber=$$v},expression:"streetNumber"}})]}}],null,false,2869640809)}):_vm._e(),(!_vm.hideStreetNumber)?_c('ValidationProvider',{ref:"searchZip",staticClass:"search-address-zip",attrs:{"rules":"required","name":"searchZip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"label":_vm.$t('addressComponent.placeholders.zip'),"error-messages":errors[0],"loading":_vm.isSuggestCheckLoad,"disabled":_vm.isDisabled,"background-color":"white","disabled":""},model:{value:(_vm.suggestionChecked.zip),callback:function ($$v) {_vm.$set(_vm.suggestionChecked, "zip", $$v)},expression:"suggestionChecked.zip"}})]}}],null,false,484802582)}):_vm._e(),(_vm.addressErrors)?_c('span',{staticClass:"error--text text-center warning-message"},[_vm._v(_vm._s(_vm.addressErrors)),_c('div',{staticClass:"manual primary--text",on:{"click":_vm.toManual}},[_vm._v(_vm._s(_vm.$t('addressComponent.placeholders.manualAdd')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }