

























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {IAddress} from '@/services/api/policy.service';
import ServerValidation from '@/mixins/serverValidation.mixin';
import {mixins} from 'vue-class-component';
import {
  ISuggestionsPlaces,
  uniformLoadingEnum
} from '@/interfaces';
import {ISuggestionAddress, ISuggestionAddressChecked} from '@/services/api/policy.service';
import '@/validators/vee-validate';
import {
  defaultAddressManual,
  defaultSuggestionChecked
} from '@/store/modules/policy.store';
import FormObserverMixin from '@/mixins/formObserver.mixin';
import {i18n} from '@/main';
import SelectSuggestions from '@/components/Ui/SelectSuggestions.vue';

@Component({
  components: {
    SelectSuggestions,
    ValidationObserver,
    ValidationProvider
  },
})
export default class AddressSearch extends mixins(ServerValidation, FormObserverMixin) {
  public $refs!: {
    searchStreetNumber: Vue
  };

  @Prop({required: true})
  private address!: IAddress;

  @Prop({type: Boolean, default: false})
  private isShowCountry: boolean | undefined;

  @Prop({type: Boolean, default: false})
  private hideStreetNumber: boolean | undefined;

  @Prop({type: String, required: false, default: () => i18n.t('addressComponent.placeholders.searching')})
  private placeholder: string | undefined;

  @Prop({type: String, required: false, default: () => i18n.t('addressComponent.placeholders.searching')})
  private label: string | undefined;

  @Prop({type: Boolean, default: false})
  private isDisabled: boolean | undefined;

  private addressErrors: string = '';
  private search: null | string = null;
  private suggestionList: ISuggestionAddress[] = [];
  private streetNumber: string | null = null;
  private suggestionChecked: ISuggestionAddressChecked = defaultSuggestionChecked();
  private timeOut: ReturnType<typeof setTimeout> | null = null;

  public async mounted() {
    if (this.address.suggestionAddress?.placeId) {
      this.suggestionList.push(this.address.suggestionAddress);
      this.suggestionCheck();
    }
  }

  private get itemText() {
    return this.address.suggestionAddress?.highlightedTitle ? 'highlightedTitle' : 'title';
  }

  private get isHouseNr() {
    return !this.address.suggestionAddress?.placeId;
  }

  private get isSuggestCheckLoad() {
    return this.$store.getters.isUniformLoading(uniformLoadingEnum.checkSuggestion);
  }

  private onSearchHouse(event) {
    if (event.target.readOnly) { return; }
    if (this.timeOut) { clearTimeout(this.timeOut); }

    this.timeOut = setTimeout(() => {
      if (event.target?.value) { this.suggestionCheck(); }
    }, 750);
  }

  private onSearch(event) {
    if (this.hideStreetNumber) {
      this.streetNumber = null;
    }
    if (this.address.suggestionAddress?.title !== event) {
      this.$store.dispatch('getSuggestions', {
        place: event,
        limitCountries: !this.isShowCountry
      })
        .then((response: ISuggestionsPlaces) => {
          if (response) {
            this.suggestionList = response.suggestions;
          }
        });
    }
  }

  private suggestionCheck() {
    if (!this.address.suggestionAddress) { return; }
    this.$store.dispatch('checkSuggestion', {
      suggestion: this.address.suggestionAddress,
      session: this.$store.getters.getSuggestSession,
      streetNumber: this.streetNumber
    })
      .then((response: ISuggestionAddressChecked) => {
        this.suggestionList.push(response.suggestion);
        this.address.suggestionAddress = response.suggestion;
        this.suggestionChecked = response;
        this.streetNumber = response.streetNumber;
        this.addressErrors = '';
      })
      .catch((error) =>
        // @ts-ignore
        this.addressErrors = this.getServerErrors(error).address || this.$t('validation.invalid')
      );
  }

  @Watch('isSearchAddressValid')
  private watchSearchAddressValid(value) {
    this.$emit('setSearchAddressValid', value);
  }

  private get isSearchAddressValid() {
    return !!(this.address.suggestionAddress?.placeId
      && this.suggestionChecked.suggestion?.placeId
      && this.suggestionChecked.streetNumber !== null
      && (!this.hideStreetNumber ? this.streetNumber !== null : true)
      && (!this.hideStreetNumber ? this.suggestionChecked.streetNumber === this.streetNumber : true)
      && this.addressErrors === ''
      && this.suggestionChecked.zip
    );
  }

  private resetInputs(event) {
    if (!event.target?.value) {
      this.suggestionChecked = defaultSuggestionChecked();
      this.streetNumber = '';
      this.observer.reset();
    }
  }

  private toManual() {
    this.address.address = defaultAddressManual();
    this.address.suggestionAddress = null;
    this.suggestionChecked = defaultSuggestionChecked();
    this.observer.reset();
    this.streetNumber = null;
    this.addressErrors = '';
    this.$emit('setSearchAddressValid', false);
    this.$emit('setManualAddress', true);
  }

}
