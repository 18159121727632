



























import {Component, Prop, Vue} from 'vue-property-decorator';
import {IAddress} from '@/services/api/policy.service';
import ServerValidation from '@/mixins/serverValidation.mixin';
import {mixins} from 'vue-class-component';
import '@/validators/vee-validate';

import FormObserverMixin from '@/mixins/formObserver.mixin';
import AddressManual from '@/components/Address/Address.manual.vue';
import AddressSearch from '@/components/Address/AddressSearch.vue';

@Component({
  inheritAttrs: false,
  components: {
    AddressManual,
    AddressSearch
  },
})
export default class Address extends mixins(ServerValidation, FormObserverMixin) {
  public $refs!: {
    searchStreetNumber: Vue
  };

  @Prop({required: true})
  private address!: IAddress;

  @Prop({type: Boolean, default: false})
  private isShowCountry: boolean | undefined;

  @Prop({type: Boolean, default: false})
  private hideStreetNumber: boolean | undefined;

  @Prop({type: Boolean, default: false})
  private isDisabled: boolean | undefined;

  @Prop({type: Boolean, default: false})
  private hideBackToSearch: boolean | undefined;

  private isManualAddress: boolean = false;
  private isManualAddressValid: boolean = false;
  private isSearchAddressValid: boolean = false;
  private isShowBackToSearch: boolean = true;

  public mounted() {
    if (!this.address?.suggestionAddress?.placeId
      && this.address?.address?.streetNumber) {
      this.isManualAddress = true;
      this.isShowBackToSearch = !this.hideBackToSearch;
      this.$emit('setManualAddress', this.isManualAddress);
    }
  }

  public get isAddressValid() {
    return this.isManualAddress
      ? this.isManualAddressValid
      : this.isSearchAddressValid;
  }

  private changeIsValid(key, val) {
    this[key] = val;
    this.$emit('setAddressValid', this.isAddressValid);
  }

  private changeIsManual(val) {
    this.isManualAddress = val;
    this.$emit('setAddressValid', this.isAddressValid);
    this.$emit('setManualAddress', val);
  }
}
