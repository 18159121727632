












































































































import { Component } from 'vue-property-decorator';
import Backlink from '@/components/Backlink.vue';
import HelpBlock from '@/components/HelpBlock.vue';
import PolicyCancelModal from '@/components/Modals/PolicyCancel.modal.vue';
import {i18n} from '@/main';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import {IPolicyFullItem, CancellationReasonEnum} from '@/interfaces';
import store from '@/store';
import {IPolicyCancellationInitiate} from '@/services/api/policy.service';
import {mixins} from 'vue-class-component';
import ServerValidation from '@/mixins/serverValidation.mixin';
import Address from '@/components/Address/Address.vue';
import {defaultAddress} from '@/store/modules/policy.store';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.policyCancel.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.policyCancel.description')
      }]
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Backlink,
    HelpBlock,
    PolicyCancelModal,
    Address
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('getPolicyInfo', to.params.id)
      .finally(() => next());
  },
})
export default class PolicyCancellationPage extends mixins(ServerValidation) {
  public $refs!: { observer: InstanceType<typeof ValidationObserver> };

  private cancelPolicyModal: boolean = false;
  private CancellationReason = CancellationReasonEnum;
  private isManualAddress: boolean = false;
  private isAddressValid: boolean = false;
  private isValidFormProperty: boolean = false;
  private addressErrors: string | null = null;

  private cancellationInfo: IPolicyCancellationInitiate = {
    reason: null,
    otherReason: '',
    address: defaultAddress(),
  };

  public created() {
    this.$store.dispatch('getDictionary', 'countries');
  }

  private async onSubmit() {
    this.cancelPolicyModal = !this.cancelPolicyModal;
  }

  private get getPolicyInfo(): IPolicyFullItem {
    return this.$store.getters.getPolicyItemInfo;
  }

  private get getFullAddress(): string | null {
    const address = this.getPolicyInfo && this.getPolicyInfo.address;
    return address
      ? `${address.street} ${address.streetNumber} ${address.zip} ${address.city}, ${address.country}`
      : null;
  }

  private async onConfirm() {
    this.cancelPolicyModal = false;
    this.$store.dispatch('cancelPolicy', {
      data: this.cancellationInfo,
      id: this.$route.params.id,
      isManual: this.isManualAddress,
    })
      .then(() => this.$router.push({name: 'policy', params: {id: this.$route.params.id}}))
      .catch((error) => {
        this.setServerValidationErrors(
          this.$refs.observer,
          this.getServerErrors(error)
        );
        // @ts-ignore
        this.addressErrors = this.getServerErrors(error).address;
      });
  }

  private clearAddress() {
    this.cancellationInfo.address = defaultAddress();
  }
}
