import {AxiosError} from 'axios';
import {ValidationObserver} from 'vee-validate';
import {Vue, Component} from 'vue-property-decorator';
import { i18n } from '@/main';
import {addressErrors} from '@/interfaces';

export type validationErrorType
  = 'ADDRESS_HAS_NO_STREET_NUMBER'
  | 'ADDRESS_HAS_NO_STREET'
  | 'ADDRESS_HAS_NO_CITY'
  | 'ADDRESS_HAS_NO_COUNTRY'
  | 'ADDRESS_HAS_NO_ZIP'
  | 'ADDRESS_HAS_NO_UNIT_NAME'
  | 'CLAIM_AMOUNT_BIGGER_THAN_DEPOSIT'
  | 'PROPERTY_HAS_ACTIVE_POLICY'
  | 'USER_WITH_PHONE_ALREADY_EXISTS'
  | 'USER_WITH_EMAIL_ALREADY_EXISTS';

export interface IServerValidationError {
  code: number;
  message: string;
  error?: validationErrorType;
  errors?: {
    [key: string]: validationErrorType|string;
  };
}

@Component
export default class ServerValidation extends Vue {
  public setServerValidationErrors(observer: Vue | Element | Vue[] | Element[], errors: Record<string, string[]>) {
    const form = (observer as InstanceType<typeof ValidationObserver>);
    form.setErrors(errors);
  }

  public getServerErrors(error: AxiosError) {
    const { errors = [] } = error.response!.data as Record<string, IServerValidationError>;
    return Object.keys(errors).reduce((acc, curr) => {
      const message = i18n.te(`validation.server.${errors[curr].error}`)
        ? i18n.tc(`validation.server.${errors[curr].error}`)
        : errors[curr].error;

      if (curr in addressErrors) {
        acc['address'] = message;
      } else {
        acc[curr] = message;
      }
      return acc;
    }, {} as Record<string, string[]>);
  }
}
